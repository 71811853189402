import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

// types
import { SliceName } from "./slice.types"
import {
  EnrichedUserData,
  EventbriteMediaResponseData,
  EventbriteOrder
} from "../../utils/auth/enrich_user_data"
import { EventbriteUserResponse } from "../../components/ProfileMenuBar/ProfileMenuBar"
import { AssortmentPlan } from "../../types/api"

export type AuthResponse = {
  language: string
  valid: boolean
  uid: number
  oid: string
  email: string
}

export type UserProfile = {
  allData: EnrichedUserData
  userData: EventbriteUserResponse
  assortmentPlan: AssortmentPlan
  email: string
  organizationId: string
  isFree: boolean
  isOrganizer: boolean
  isNewAccount: boolean
  isHighValue: boolean
  isSalesCreator: boolean
  isSubscribed?: boolean
  isLegacy?: boolean
  isFlex?: boolean
  avatar?: EventbriteMediaResponseData | Record<string, never>
  name?: string
  orders?: EventbriteOrder[]
}

const initialState: UserProfile | null = null

export const userProfileSlice = createSlice({
  name: SliceName.userProfile,
  initialState,
  reducers: {
    setUserProfile: (s, action: PayloadAction<UserProfile>) => {
      const finalState = {
        ...s,
        ...action.payload
      }

      return finalState
    },
    logout: () => {
      return null
    }
  }
})

// Action creators are generated for each case reducer function
export const { setUserProfile, logout } = userProfileSlice.actions

export const userProfileReducer = userProfileSlice.reducer
