import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"

// redux
import { SliceName } from "./slice.types"

export type BaseState = {
  langCode: string
  hasAttemptedLogin: boolean
  cxBusHasLoaded: boolean
}

const initialState: BaseState = {
  langCode: "",
  hasAttemptedLogin: false,
  cxBusHasLoaded: false
}

export const metaDataSlice = createSlice({
  name: SliceName.metaData,
  initialState,
  reducers: {
    setMetaData: (s, action: PayloadAction<Partial<BaseState>>) => {
      return { ...s, ...action.payload }
    }
  }
})

export const { setMetaData } = metaDataSlice.actions

export const metaDataReducer = metaDataSlice.reducer
