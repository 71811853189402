import React from "react"
import { Provider } from "react-redux"
import { GatsbyBrowser } from "gatsby"

// redux
import { store } from "./src/store/store"

// @TODO: this is a short term fix for Marmalde styles. We have to import each component's stylesheet manually for now.
// @ts-ignore
import * as buttonStyles from "@eventbrite/marmalade/lib/components/Button/Button.css"
// @ts-ignore
import * as checkboxStyles from "@eventbrite/marmalade/lib/components/Checkbox/Checkbox.css"
// @ts-ignore
import * as choiceCardStyles from "@eventbrite/marmalade/lib/components/ChoiceCard/ChoiceCard.css"
// @ts-ignore
import * as choiceListStyles from "@eventbrite/marmalade/lib/components/ChoiceList/ChoiceList.css"
// @ts-ignore
import * as inlineAlertStyles from "@eventbrite/marmalade/lib/components/InlineAlert/InlineAlert.css"
// @ts-ignore
import * as radioStyles from "@eventbrite/marmalade/lib/components/Radio/Radio.css"
// @ts-ignore
import * as textAreaStyles from "@eventbrite/marmalade/lib/components/TextArea/TextArea.css"
// @ts-ignore
import * as textFieldStyles from "@eventbrite/marmalade/lib/components/TextField/TextField.css"
// @ts-ignore
import * as selectFieldStyles from "@eventbrite/marmalade/lib/components/SelectField/SelectField.css"
// @ts-ignore
import * as formControlStyles from "@eventbrite/marmalade/lib/components/FormControl/FormControl.css"
// @ts-ignore
import * as iconStyles from "@eventbrite/marmalade/lib/components/Icon/Icon.css"

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element
}) => {
  return (
    <Provider
      store={store}
      // @ts-ignore
      styles={{
        choiceCardStyles,
        buttonStyles,
        checkboxStyles,
        inlineAlertStyles,
        choiceListStyles,
        textAreaStyles,
        textFieldStyles,
        radioStyles,
        formControlStyles,
        selectFieldStyles,
        iconStyles
      }}
    >
      {element}
    </Provider>
  )
}
